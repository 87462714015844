import React from 'react'

const team = [
  {
    name: 'Arunesh Mathur',
    caption: 'Postdoctoral Research Fellow',
    href: 'http://aruneshmathur.co.in/',
  },
  {
    name: 'Angelina Wang',
    caption: 'Graduate Student',
    href: 'https://angelina-wang.github.io/',
  },
  {
    name: 'Carsten Schewemmer',
    caption: 'Postdoctoral Research Fellow',
    href: 'https://www.carstenschwemmer.com/',
  },
  {
    name: 'Maia Hamin',
    caption: 'Undergraduate Student',
    href: 'https://electionemails2020.org/#',
  },
  {
    name: 'Brandon Stewart',
    caption: 'Assistant Professor',
    href: 'https://scholar.princeton.edu/bstewart/home',
  },
  {
    name: 'Arvind Narayanan',
    caption: 'Assistant Professor',
    href: 'https://www.cs.princeton.edu/~arvindn/',
  },
]



const TeamMembers = () => {
  return (
    <div className="grid grid-cols-1 mt-5 mb-6 md:grid-cols-2">
      {team.map((item) => (
        <div className="pb-6" key={item.name}>
          <a href={item.href} className="py-3 text-lg transition-all " target="_blank" rel="noopener">
            {item.name}
          </a>
          <p>{item.caption}</p>
        </div>
      ))}
    </div>
  )
}

export default TeamMembers
